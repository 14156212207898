#home {
  position: relative;
  background: url("../../assets/bgIMG2.jpg");
  // <a href="https://www.vecteezy.com/free-vector/design">Design Vectors by Vecteezy</a>
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}
.app__about {
  position: absolute;
  z-index: 1;
}

.app__header-p {
  font-size: 1.2rem;
  text-align: center;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 1400px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 992px) {
    font-size: 1rem;
  }
}

$map: (ring-0: 4, ring-1: 3, ring-2: 3);
$orbitItemSize: 2.5em;

.app__orbit {
  float: left;
  width: 100%;

  @media screen and (max-width: 40em) {
    display: none;
  }
}

.app__orbit-icon {
  width: $orbitItemSize;
  height: $orbitItemSize;
  line-height: $orbitItemSize;
  font-size: 1.2em;
  border-radius: 50%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: block;
}

.app__orbit-wrap {
  height: 40em;
  list-style: none;
  font-size: 1.2em;
  
  > li {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    &:hover {
      ul {
        border-width: 4px;
        border-color: #fff;
      }
      ~ li ul {
        border-color: rgba(255, 255, 255, 0.2);
        li {
          opacity: 0.4;          
        }
      }
    }
    
  }
}

@mixin orbit-item($numItems, $numRing) {
  @for $s from 1 through $numItems {  
    // Spread items over the ring
    $deg: 360deg / $numItems;
    
    .ring-#{$numRing} > *:nth-of-type(#{$s}) {
      transform: rotate($s * $deg) translate(20em - ($numRing * 5)) rotate(-$s * $deg);
    }
  }
}

ul[class^=ring] {
  @extend %ring;
  transition: all 300ms ease-in-out;
  li {
    @extend %orbiting-object;
    transition: all 300ms ease-in-out;
  }
}

// Render rings
@for $i from 0 through 2 {  
  .ring-#{$i} {
    // decrease each ring in size
    width: 40em - ($i * 10);
    height: 40em - ($i * 10);
    animation: clockwiseRotate 35s - ($i * 5) linear infinite;
    
    i {
      animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
    }
  }
  
  @include orbit-item(map-get($map, ring-#{$i}), $i);
}


%ring {
  border: solid 1px rgba(33, 150, 243, 0.8);
  position: relative;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
}

%orbiting-object {
  display: block;
  position: absolute;
  top:50%;
  left: 50%;
  width: $orbitItemSize;
  height: $orbitItemSize;
  margin: -$orbitItemSize / 2;
}

@keyframes clockwiseRotate {
  from {
      transform: rotate(0deg)
  }
  to {
      transform: rotate(360deg)
  }
}

@keyframes counterClockwiseRotate {
0% {
  transform: rotate(0deg);
}
100% {
    transform: rotate(-360deg);
}
}

@media screen and (max-width: 992px) {
  $orbitItemSize: 1.6em;

  .app__orbit-icon {
    width: $orbitItemSize;
    height: $orbitItemSize;
    line-height: $orbitItemSize;
  }

  @mixin orbit-item($numItems, $numRing) {
    @for $s from 1 through $numItems {  
      // Spread items over the ring
      $deg: 360deg / $numItems;
      
      .ring-#{$numRing} > *:nth-of-type(#{$s}) {
        transform: rotate($s * $deg) translate(12.5em - ($numRing * 2.5)) rotate(-$s * $deg);
      }
    }
  }

  @for $i from 0 through 2 {  
    .ring-#{$i} {
      // decrease each ring in size
      width: 25em - ($i * 5);
      height: 25em - ($i * 5);
      animation: clockwiseRotate 35s - ($i * 5) linear infinite;
      
      i {
        animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
      }
    }
    
    @include orbit-item(map-get($map, ring-#{$i}), $i);
  }
  %orbiting-object {
    width: $orbitItemSize;
    height: $orbitItemSize;
    margin: -$orbitItemSize / 2;
  }
}

@media screen and (min-width: 1400px) {
  $orbitItemSize: 3.2em;

  .app__orbit-icon {
    width: $orbitItemSize;
    height: $orbitItemSize;
    line-height: $orbitItemSize;
  }

  @mixin orbit-item($numItems, $numRing) {
    @for $s from 1 through $numItems {  
      // Spread items over the ring
      $deg: 360deg / $numItems;
      
      .ring-#{$numRing} > *:nth-of-type(#{$s}) {
        transform: rotate($s * $deg) translate(25em - ($numRing * 5)) rotate(-$s * $deg);
      }
    }
  }

  @for $i from 0 through 2 {  
    .ring-#{$i} {
      // decrease each ring in size
      width: 50em - ($i * 10);
      height: 50em - ($i * 10);
      animation: clockwiseRotate 35s - ($i * 5) linear infinite;
      
      i {
        animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
      }
    }
    
    @include orbit-item(map-get($map, ring-#{$i}), $i);
  }
  %orbiting-object {
    width: $orbitItemSize;
    height: $orbitItemSize;
    margin: -$orbitItemSize / 2;
  }
}