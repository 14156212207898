.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 992px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  margin: 1rem 0;
}

  .app__skills-exp-work {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;

    h4 {
      font-weight: 600;
    }

    p {
      font-weight: 500;
      color: var(--secondary-color);
      margin-left: 3rem;
    }
  }

.app__skills-exp-desc {
  margin-top: -10px;
}